import { terminalsApiUrl, terminalsUrl } from "@app/actions/brand-actions";
import { FormDrawer } from "@app/components/FormDrawer/FormDrawer";
import { BrandFormActionsTab } from "@app/pages/brands/BrandForm/tabs/ActionsTab";
import { BrandFormGeneralTab } from "@app/pages/brands/BrandForm/tabs/GeneralTab";
import { BrandFormInfoTab } from "@app/pages/brands/BrandForm/tabs/InfoTab";
import { BrandFormNotesTab } from "@app/pages/brands/BrandForm/tabs/NotesTab";
import { BrandFormSubscriptionTab } from "@app/pages/brands/BrandForm/tabs/SubscriptionTab";
import VenuesContainer from "@app/pages/brands/BrandForm/tabs/VenuesContainer";
import { ClientForm } from "@app/pages/clients/ClientForm/ClientForm";
import { sessionStore } from "@app/stores/SessionStore";
import { Button, Form, Tabs } from "antd";
import { FormComponentProps } from "antd/lib/form";
import _ from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactJson from "react-json-view";
import { Link } from "react-router-dom";
import { LoginButton } from "../BrandsPage/LoginButton";
import { SubscriptionForm } from "../SubscriptionForm/SubscriptionForm";
import { brandFormStore } from "./BrandFormStore";
import { ajax } from "@app/lib/ajax";

@observer
class BrandFormBare extends React.Component<FormComponentProps> {
    public handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields((err: Error | undefined, values: any) => {
            if (!err) {
                brandFormStore.save();
            }
        });
    };

    private submitButtons(): JSX.Element {
        return (
            <div>
                <Button type="primary" loading={brandFormStore.loading} htmlType="submit" style={{ marginRight: 5 }}>
                    Save
                </Button>
                <Button type="default" onClick={brandFormStore.hide} htmlType="button">
                    Cancel
                </Button>
            </div>
        );
    }

    public async copyCredentials(brand: IBrand): Promise<void> {
        const terminals = await ajax.get({
            url: terminalsApiUrl(brand.id!),
        });
        const terminalsTmp = terminals.data.map((t: ITerminal) => `${t.name}:\t\t${t.password}`).join("\n");
        const tmp = `https://client.clopos.com/${brand.name}/1
Brand name : ${brand.name}
Email :  ${brand.client?.email}
Password : ${brand.client?.last_password}

Terminal passwords:
${terminalsTmp}
 `;

        navigator.clipboard.writeText(tmp);
    }

    public render(): JSX.Element | null {
        const brand = brandFormStore.data;
        if (!brand) {
            return null;
        }

        const brandData = toJS(brandFormStore.data);
        const isCreate = _.isNil(brandData?.id);
        const tabBodyHeight = "calc(100vh - 130px)";

        return (
            <FormDrawer
                store={brandFormStore}
                bodyStyle={{ padding: 0, height: "calc(100vh - 70px)" }}
                title={
                    <div>
                        <span>{brandFormStore.title}</span>
                        {!_.isNil(brandFormStore._data.id) && (
                            <>
                                <LoginButton small id={brand.id!} stage={brand.stage} style={{ marginLeft: 15 }} />
                                <Button onClick={() => this.copyCredentials(brand)}>Copy credentials</Button>
                                <Link style={{ marginLeft: 8, fontSize: 13 }} to={terminalsUrl(brand.id!)}>
                                    Terminals
                                </Link>
                            </>
                        )}
                    </div>
                }
            >
                <ClientForm />
                <SubscriptionForm />

                <Tabs defaultActiveKey="1" style={{ height: "calc(100vh - 70px)" }}>
                    {sessionStore.role?.level !== 2 && !_.isNil(brandFormStore._data.id) && (
                        <Tabs.TabPane tab="Info" key="-1">
                            <BrandFormInfoTab />
                        </Tabs.TabPane>
                    )}

                    {sessionStore.role?.level !== 2 && (
                        <Tabs.TabPane tab="General" key="1">
                            <BrandFormGeneralTab
                                form={this.props.form}
                                isCreate={isCreate}
                                handleSubmit={this.handleSubmit}
                                buttons={this.submitButtons}
                            />
                        </Tabs.TabPane>
                    )}

                    {!isCreate && sessionStore.role?.level !== 2 && (
                        <Tabs.TabPane tab="Venues" key="2">
                            <VenuesContainer brand={brand} />
                        </Tabs.TabPane>
                    )}

                    {sessionStore.role?.level !== 2 && (
                        <Tabs.TabPane tab="Subscription" key="3">
                            <BrandFormSubscriptionTab
                                isCreate={isCreate}
                                form={this.props.form}
                                handleSubmit={this.handleSubmit}
                                buttons={this.submitButtons}
                            />
                        </Tabs.TabPane>
                    )}

                    <Tabs.TabPane tab="Notes" key="4">
                        <BrandFormNotesTab
                            isCreate={isCreate}
                            form={this.props.form}
                            handleSubmit={this.handleSubmit}
                            buttons={this.submitButtons}
                        />
                    </Tabs.TabPane>

                    {!isCreate && sessionStore.role?.level !== 2 && (
                        <Tabs.TabPane tab="Actions" key="5" style={{ height: tabBodyHeight, overflow: "auto" }}>
                            <BrandFormActionsTab />
                        </Tabs.TabPane>
                    )}

                    {sessionStore.role?.level !== 2 && (
                        <Tabs.TabPane tab="JSON" key="6" style={{ height: tabBodyHeight, overflow: "auto" }}>
                            <ReactJson src={brandData} name={brandFormStore.dataName} />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </FormDrawer>
        );
    }
}

export const BrandForm = Form.create()(BrandFormBare);
